import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { Object3D, AnimationMixer, AnimationAction, LoopOnce, AnimationClip, Mesh, Texture, MeshLambertMaterial } from 'three';
import { IPainter2d } from './CanvasRenderer';
import { PlaneRenderer, Size } from './PlaneRenderer';

const HoverEvent = 'hover';
const UnhoverEvent = 'unhover';
const RepaintEvent = 'repaint';

type Inputs = {
  loadingState: string;
  texture: Texture | null;
  updateInterval: number;
  minValTemp: number;
  maxValTemp: number;
  minValAnalog: number;
  maxValAnalog: number;
  minWarningValTemp: number;
  maxWarningValTemp: number;
  minWarningValAnalog: number;
  maxWarningValAnalog: number;
  unit: string;
  resoureId: string,
  authKeyId: string,
  authKey: string,
  tokenTimeoutSeconds: number,
  authUrl: string,
  resourceApiUrl: string,
  dataLimit: number,
  dataSort: string
}

type ApiResponseData = {
  content: Object,
  time: number
}

type Outputs = {
  painter: IPainter2d | null;
  visible: boolean;
} & ComponentOutput;


class IotSoracomVisualizer extends SceneComponent implements IPainter2d {
  private daeComponent: SceneComponent;
  private mixer: AnimationMixer | null = null;
  private onEnterClip: AnimationClip | null = null;
  private mesh: Mesh | null = null;
  private currentTime: number = 0;
  private nextUpdate: number = 0;
  private apiKey: string = "";
  private token: string = "";
  private tokenValidDate: Date = null;
  private dataToProcess: ApiResponseData[] = null;

  inputs: Inputs = {
    loadingState: 'Idle',
    texture: null,
    updateInterval: 1000,
    minValTemp: 0,
    maxValTemp: 1,
    minValAnalog: 0,
    maxValAnalog: 1,
    minWarningValTemp: 0,
    maxWarningValTemp: 1,
    minWarningValAnalog: 0,
    maxWarningValAnalog: 1,
    unit: "x",
    resoureId: "",
    authKeyId: "",
    authKey: "",
    tokenTimeoutSeconds: 86400,
    authUrl: "",
    resourceApiUrl: "",
    dataLimit: 1,
    dataSort: "desc"
  }

  outputs = {
    painter: null,
    visible: false,
  } as Outputs;

  events = {
    [HoverEvent]: true,
    [UnhoverEvent]: true,
  };

  onInit() {
    const root = this.context.root;
    const THREE = this.context.three;

    let planeRenderer: PlaneRenderer;
    for (const component of root.componentIterator()) {
      if (component.componentType === 'mp.daeLoader') {
        this.daeComponent = component;
      }
      else if (component.componentType === 'mp.planeRenderer') {
        planeRenderer = component as PlaneRenderer;
        planeRenderer.outputs.objectRoot.translateZ(0.05);
        planeRenderer.outputs.objectRoot.translateY(0.4);
        planeRenderer.outputs.objectRoot.scale.set(0.5, 0.5, 0.5);
      }
    }


    this.outputs.painter = this;

    this.mixer = new THREE.AnimationMixer(planeRenderer.outputs.objectRoot);

    const tm = 0.2;
    const positionTrack = new THREE.VectorKeyframeTrack('.scale', [0, tm], [
      0, 0, 0,
      0.5, 0.5, 0.5
    ], THREE.InterpolateSmooth);
    this.onEnterClip = new THREE.AnimationClip(null, tm, [positionTrack]);
  }

  onInputsUpdated() {
    const THREE = this.context.three;
    if (this.inputs.loadingState === 'Loaded') {
      this.daeComponent.outputs.objectRoot.traverse((obj: Object3D) => {
        // we dont want line segments
        if (obj.type === 'LineSegments') {
          obj.visible = false;
        }
        else if (obj.type === 'Mesh') {
          this.mesh = obj as Mesh;

          const material = this.mesh.material as MeshLambertMaterial;
          if (material && material.name === '_5b76dbe388862300126c1e14') {
            const newMaterial = new THREE.MeshBasicMaterial({ map: this.inputs.texture });
            this.mesh.material = newMaterial;
          }
        }
      });
    }
  }

  onEvent(eventType: string, eventData: unknown): void {
    if (eventType === HoverEvent) {
      const data: any = eventData;
      if (data.hover) {
        this.outputs.visible = true;
        const onEnterAction: AnimationAction = this.mixer.clipAction(this.onEnterClip);
        onEnterAction.stop();
        onEnterAction.loop = LoopOnce;
        onEnterAction.clampWhenFinished = true;
        onEnterAction.play();
      }
      else {
        this.outputs.visible = false;
      }
    }
  }

  paintTemp(context2d: CanvasRenderingContext2D, value: number, time: number) {
    //Draw something context2d
    context2d.drawImage
    const dialCenter_X = 512;
    const dialCenter_Y = 512;
    const dialBGradius = 400;
    const dialFaceradius = 300;
    const dialIndicatorradius = 240;
    //drawing dial background,black.
    context2d.fillStyle = 'black';
    context2d.beginPath();
    context2d.arc(dialCenter_X, dialCenter_Y, dialBGradius, 0, Math.PI * 2);
    context2d.fill();
    //drawing dial face,state responsive
    if (value > this.inputs.maxWarningValTemp || value < this.inputs.minWarningValTemp) {
      context2d.fillStyle = 'red';
    }
    else {
      context2d.fillStyle = 'green';
    }
    context2d.beginPath();
    context2d.arc(dialCenter_X, dialCenter_Y, dialFaceradius, 0, Math.PI * 2);
    context2d.fill();
    //drawing dial value indicator,data responsive.
    context2d.beginPath();
    context2d.strokeStyle = 'orange';
    context2d.arc(dialCenter_X, dialCenter_Y, dialIndicatorradius, 1.00 * Math.PI, (((value - this.inputs.minValTemp) * (2.00 * Math.PI - 1.00 * Math.PI)) / (this.inputs.maxValTemp - this.inputs.minValTemp)) + 1.00 * Math.PI, false);
    context2d.lineCap = 'butt';
    context2d.lineWidth = 80;
    context2d.stroke();
    //drawing value of data and unit of data.
    context2d.fillStyle = 'white';
    context2d.font = '100px Arial';
    context2d.textAlign = 'center';
    context2d.fillText(`${value}`, dialCenter_X, dialCenter_Y);
    context2d.fillText(`Temp`, dialCenter_X, dialCenter_Y + (dialFaceradius * 0.70));
    //drawing date and time of data
    context2d.fillStyle = 'white';
    context2d.font = '100px Arial';
    context2d.textAlign = 'center';
    context2d.fillText(`Date: ${new Date(time).toLocaleDateString()}`, dialCenter_X + 1000, dialCenter_Y - 150);

    context2d.fillStyle = 'white';
    context2d.font = '100px Arial';
    context2d.textAlign = 'center';
    context2d.fillText(`Time: ${new Date(time).getHours()}:${new Date(time).getMinutes()}:${new Date(time).getSeconds()}`, dialCenter_X + 1000, dialCenter_Y + 150);

    return context2d;
  }

  paintAnalogInput(context2d: CanvasRenderingContext2D, value: number, time: number) {
    //Draw something context2d
    const dialCenter_X = 512;
    const dialCenter_Y = 1536;
    const dialBGradius = 400;
    const dialFaceradius = 300;
    const dialIndicatorradius = 240;
    //drawing dial background,black.
    context2d.fillStyle = 'black';
    context2d.beginPath();
    context2d.arc(dialCenter_X, dialCenter_Y, dialBGradius, 0, Math.PI * 2);
    context2d.fill();
    //drawing dial face,state responsive
    if (value > this.inputs.maxWarningValAnalog || value < this.inputs.minWarningValAnalog) {
      context2d.fillStyle = 'red';
    }
    else {
      context2d.fillStyle = 'green';
    }
    context2d.beginPath();
    context2d.arc(dialCenter_X, dialCenter_Y, dialFaceradius, 0, Math.PI * 2);
    context2d.fill();
    //drawing dial value indicator,data responsive.
    context2d.beginPath();
    context2d.strokeStyle = 'orange';
    context2d.arc(dialCenter_X, dialCenter_Y, dialIndicatorradius, 1.00 * Math.PI, (((value - this.inputs.minValAnalog) * (2.00 * Math.PI - 1.00 * Math.PI)) / (this.inputs.maxValAnalog - this.inputs.minValAnalog)) + 1.00 * Math.PI, false);
    context2d.lineCap = 'butt';
    context2d.lineWidth = 80;
    context2d.stroke();
    //drawing value of data and unit of data.
    context2d.fillStyle = 'white';
    context2d.font = '100px Arial';
    context2d.textAlign = 'center';
    context2d.fillText(`${value}`, dialCenter_X, dialCenter_Y);
    context2d.font = '75px Arial';
    context2d.fillText(`AnalogInput(V)`, dialCenter_X, dialCenter_Y + (dialFaceradius * 0.50));
    //drawing date and time of data
    context2d.fillStyle = 'white';
    context2d.font = '100px Arial';
    context2d.textAlign = 'center';
    context2d.fillText(`Date: ${new Date(time).toLocaleDateString()}`, dialCenter_X + 1000, dialCenter_Y - 150);

    context2d.fillStyle = 'white';
    context2d.font = '100px Arial';
    context2d.textAlign = 'center';
    context2d.fillText(`Time: ${new Date(time).getHours()}:${new Date(time).getMinutes()}:${new Date(time).getSeconds()}`, dialCenter_X + 1000, dialCenter_Y + 150);

    return context2d;
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    if (!this.dataToProcess) {
      return;
    }
    context2d.clearRect(0, 0, 2048, 2048);
    context2d.beginPath();
    context2d.strokeStyle = 'white';
    context2d.lineWidth = 75;
    context2d.moveTo(0, 1024);
    context2d.lineTo(2048, 1024);
    context2d.stroke();

    for (var i = 0; i < this.dataToProcess.length; i++) {
      var data = this.dataToProcess[i];

      Object.entries(data.content).forEach(
        ([key, value]) => {
          if (key == "Temp") {
            context2d = this.paintTemp(context2d, value, data.time);
          }

          if (key == "AnalogInput(V)") {
            context2d = this.paintAnalogInput(context2d, value, data.time);
          }
        }
      );
    }
  }

  requestAuth() {
    return new Promise<{ statusText: string, readyState: number, status: number, blob: string }>((resolve) => {
      let requestAuth = new XMLHttpRequest();
      requestAuth.open("POST", this.inputs.authUrl);
      requestAuth.setRequestHeader('accept', 'application/json');
      requestAuth.setRequestHeader('Content-Type', 'application/json');
      var data = {
        "tokenTimeoutSeconds": this.inputs.tokenTimeoutSeconds
      }

      requestAuth.onload = () => {
        resolve({
          statusText: requestAuth.statusText,
          readyState: requestAuth.readyState,
          status: requestAuth.status,
          blob: requestAuth.response
        });
      };

      requestAuth.send(JSON.stringify(data));
    });

  }

  requestApiData(apiKey: string, token: string) {
    return new Promise<{ statusText: string, readyState: number, status: number, blob: string }>((resolve) => {
      let requestData = new XMLHttpRequest();
      let requestUrl = new URL(this.inputs.resourceApiUrl);
      let requestParams = new URLSearchParams(requestUrl.search);

      var getParams = {
        "sort": "desc",
        "limit": 2,
        "X-Soracom-API-Key": apiKey,
        "X-Soracom-Token": token,
        "resoureId": this.inputs.resoureId
      }

      Object.entries(getParams).forEach(
        ([key, value]) => {
          requestParams.append(key, value.toString());
        }
      );

      requestData.open("GET", this.inputs.resourceApiUrl + "?" + requestParams.toString());
      requestData.setRequestHeader('accept', 'application/json');
      requestData.setRequestHeader('Content-Type', 'application/json');

      requestData.onload = () => {
        resolve({
          statusText: requestData.statusText,
          readyState: requestData.readyState,
          status: requestData.status,
          blob: requestData.response
        });

      };

      requestData.send();
    });

  }

  async onTick(delta: number) {
    this.currentTime += delta;

    if (this.mixer) {
      this.mixer.update(delta / 1000);
    }

    if (this.currentTime > this.nextUpdate) {
      this.nextUpdate += this.inputs.updateInterval;

      let resultAuth = null;
      let resultApiData = null;

      let currentTime = new Date();
      if (!this.tokenValidDate || (this.tokenValidDate.getTime() - currentTime.getTime() <= 0)) {
        this.apiKey = "";
        this.token = "";
        this.tokenValidDate = null;
      }

      if (!this.apiKey || !this.token) {
        resultAuth = await this.requestAuth();

        if (resultAuth.readyState === 4 && resultAuth.status === 200) {
          //console.log(resultAuth.blob);
          var parseAuthContent = JSON.parse(resultAuth.blob);
          //console.log("Request response from soracom: "+parseAuthContent);
          this.apiKey = parseAuthContent.apiKey;
          //console.log("parseAuthContent.apiKey: "+parseAuthContent.apiKey);
          this.token = parseAuthContent.token;
          //console.log("parseAuthContent.token: "+parseAuthContent.token);

          this.tokenValidDate = new Date(Date.now() + this.inputs.tokenTimeoutSeconds * 1000);
        } else {
          console.log(`error ${resultAuth.status} ${resultAuth.readyState} ${resultAuth.statusText}`);
        }
      }

      resultApiData = await this.requestApiData(this.apiKey, this.token);
      var parseApiContents = JSON.parse(resultApiData.blob);

      if (parseApiContents.length === 0) {
        parseApiContents = [
          {
            "content": `{\"Temp\":${Math.floor(Math.random() * 100)}}\r\n`,
            "contentType": "application/json",
            "time": Date.now()
          },
          {
            "content": `{\"AnalogInput(V)\":${Math.random().toFixed(2)}}\r\n`,
            "contentType": "application/json",
            "time": Date.now()
          }
        ];
      }


      var parseApiData = [];
      for (var i = 0; i < parseApiContents.length; i++) {
        var parseApiContent = parseApiContents[i];
        parseApiData.push({
          content: JSON.parse(parseApiContent.content),
          time: parseApiContent.time
        });
      }

      this.dataToProcess = parseApiData;

      this.notify(RepaintEvent);
    }
  }
}

export const IotSoracomVisualizerType = 'mp.IotSoracomVisualizer';
export const makeIotSoracomVisualizer = function () {
  return new IotSoracomVisualizer();
}
