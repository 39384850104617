import React from "react";
import background from "../assets/images/toggle_panel_frame.png";
import backIcon from "../assets/images/bak_icon.png";

type Props = {
  isBackButtonShown: boolean;
};

const BackButton = (props: Props) => {
  if (!props.isBackButtonShown) {
    return null;
  }
  return (
    <div className="toggleButtonContainer">
      <img className="imageStyle" src={background} />
      <h1 className="textStyle">{"BACK"}</h1>
      <div className="togglePlacement">
        <img className="backIconStyle" src={backIcon} />
      </div>
    </div>
  );
};

export default BackButton;
