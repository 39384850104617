//import scene from '../assets/vs-app.json'; //Original Line
//import sceneJSON from '../assets/ORGvs-app.json';
// import sceneJSON from '../assets/rNG882oSGVz_IoT_vs-app.json';
import sceneJSON from '../assets/BareBone-vs-app.json';
import { ISceneNode } from '@mp/common';


const sidToScene: Map<string, any> = new Map();
let scenenarioJSON: string;

export class SceneLoader {
  private nodes: ISceneNode[] = [];

  constructor(private sdk: any, scenarioJson_CMS: string) {
    scenenarioJSON = scenarioJson_CMS;
  }

  /**
   * Load the scene for a given model.
   * 
   * @param sid sid of the model, used to lookup the scene.
   * @param callback an optional callback which is called once for scene node created.
   */
  public async load(sid: string, callback: (node: ISceneNode) => void = null) {
    const nodesToStop = this.nodes.splice(0);
    for (const node of nodesToStop) {
      node.stop();
    }

    if (scenenarioJSON === "") {
      // console.log("JSON SCENARIO FILE NOT FOUND FROM CMS:::")
      // console.log("LOADING DEFAULT EMPTY JSON")
      sidToScene.set(`${sid}`, sceneJSON);
    } else {
      // console.log("JSON SCENARIO FILE FOUND FROM CMS PARSED:::", JSON.parse(scenenarioJSON))
      // console.log("scenenarioJSON in Sceneloader:::", JSON.parse(scenenarioJSON))
      sidToScene.set(`${sid}`, JSON.parse(scenenarioJSON));
    }

    // console.log(sceneJSON);


    const scene = sidToScene.get(sid);
    if (!scene) {
      return;
    }

    //const nodesToStart: ISceneNode[] = await this.sdk.Scene.deserialize(JSON.stringify(scene));

    //Workaround for the break change for 22.11.1
    //Reference URL: https://matterport.github.io/showcase-sdk/sdk_changelog.html#22111
    const objectDeserialized = await this.sdk.Scene.deserialize(JSON.stringify(scene));
    const nodesToStart = [...objectDeserialized.nodeIterator()];

    if (callback) {
      //console.log("See nodes: ", nodesToStart);
      for (const node of nodesToStart) {
        callback(node);
      }
    }

    for (const node of nodesToStart) {
      node.start();
      this.nodes.push(node);
    }
  }

  public *nodeIterator(): IterableIterator<ISceneNode> {
    for (const node of this.nodes) {
      yield node;
    }
  }
}

// const sidToScene: Map<string, any> = new Map(); //original line | see above for the same function,it has been moved up for readability

//sidToScene.set('SuebkWJgrQq', scene);//Default line
//sidToScene.set('v6HzHUTNnjR', scene_X);

//EXPLANATION FOR PERSONAL REFERENCE ::=> sidToScene.set('space id or sid' , 'scene file or the JSON file(eg: vs-app.json) ')  it basically associates a specific sid to a specific JSON file.
// like a key value pair dictionary.here it was originally declared manually hardcoded.now we set it dynamically
//Some sid for quick test and check: CcWT1kCqwgs , v6HzHUTNnjR , SuebkWJgrQq
