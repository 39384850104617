import React from "react";
import HeaderBoxImg from "../assets/images/Title_box.png";
import ListBoxImg from "../assets/images/PDf_box.png";
import ListArrowImg from "../assets/images/arrow.png";

export interface ExternalFileListProps {
  externalDocList: string;
  SDK_REF?: any;
}

export function ExternalFileList(props: ExternalFileListProps) {
  if (props.externalDocList === "") {
    console.log("external data Json file empty:::ExternalFileList.tsx:::");
  } else {
    console.log(
      "JSON.parse ing it:::ExternalFileLise.tsx:::",
      JSON.parse(props.externalDocList)
    );
  }
  let tableDetails: [] = JSON.parse(props.externalDocList).linkDetails;
  const toDocSweepPoint = (sid: string, x_rot: number, y_rot: number) => {
    return props.SDK_REF.Sweep.moveTo(sid, {
      rotation: { x: x_rot, y: y_rot },
      transition: props.SDK_REF.Sweep.Transition.FADEOUT,
    })
      .then()
      .catch();
  };

  return (
    <div className="externalFileListTable">
      {tableDetails.map((item: any) => (
        <div key={item.header} className="DocumentTable">
          <div className="headerContainer">
            <img className="headerBoxImg" src={HeaderBoxImg} />
            <h2 className="tabelHeaderText">{item.header}</h2>
          </div>

          {item.links.map((link: any, rowNumber: number) => {
            console.log("rowlink:", link);
            return (
              <div className="listItemContainer">
                <div className="listBoxImgContainer">
                  <img className="headerBoxImg" src={ListBoxImg} />
                </div>
                <div className="tabelRow" key={link.linkHeader}>
                  <div className="rowNumber">{rowNumber + 1}</div>
                  <a className="rowLink" href={link.link} target="blank">
                    {""}
                    {link.linkHeader}
                  </a>
                  <div className="rowRefToSweep">
                    <img
                      src={ListArrowImg}
                      alt=""
                      onClick={() =>
                        toDocSweepPoint(
                          link.sweep,
                          link.sweepRot["x"],
                          link.sweepRot["y"]
                        )
                      }
                    />
                    {/* <img src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/plainTriangle.png" alt="" /> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
