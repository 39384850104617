import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";

import {
  API_URL,
  AWS_COOKIE_DOMAIN,
  AWS_IDENTITY_POOL_ID,
  AWS_POOL_WEB_CLIENT_ID,
  AWS_REGION,
  AWS_USER_POOL_ID,
  live360DataBucket,
  NODE_ENV,
  subFolderName,
} from "../Live360Config";
import Content from "./Content";
import ContentListPage from "./ContentListPage";
import LoginPage from "./LoginPage";
import ChangePasswordPage from "./ChangePasswordPage";
import PasswordResetPage from "./PasswordResetPage";

// import { Footer } from "./Footer";
import Error from "./Error";
import NewVideo from "./NewVideo";

Amplify.configure({
  Auth: {
    identityPoolId: AWS_IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: AWS_USER_POOL_ID,
    userPoolWebClientId: AWS_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: AWS_COOKIE_DOMAIN,
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      // sameSite: "strict" | "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: NODE_ENV === "production",
    },
  },
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

type AuthCheckState = {
  permissionState: string;
  cognitoUserFound: boolean;
};
type MyProps = {};

const getCurrentUserDetails = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((sessionData) => {
        // let sub: string = sessionData.getIdToken().payload.sub;
        // let jwtToken: string = sessionData.getIdToken().getJwtToken();
        resolve(true);
      })
      .catch((err) => {
        reject(false);
      });
  });
};

class RouteHandler extends Component<MyProps, AuthCheckState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      permissionState: "notPermitted",
      cognitoUserFound: false,
    };
  }

  authStateUpdate = () => {
    this.setState({
      permissionState: "isPermitted",
    });
  };

  componentDidMount(): void {
    getCurrentUserDetails().then((result) => {
      this.setState({ cognitoUserFound: result as boolean });
    });
  }

  render = () => (
    <BrowserRouter>
      <Switch>
        <Route exact path={subFolderName === null ? "/" : `/${subFolderName}`}>
          <ContentListPage />
        </Route>

        <Route
          exact
          path={subFolderName === null ? "/login" : `/${subFolderName}/login`}
        >
          {this.state.cognitoUserFound ? (
            <Redirect to={"/"} />
          ) : (
            <>
              <LoginPage from="/" loginSuccess={() => this.authStateUpdate()} />
            </>
          )}
        </Route>

        <Route
          exact
          path={
            subFolderName === null
              ? "/ChangePassword"
              : `/${subFolderName}/ChangePassword`
          }
        >
          <ChangePasswordPage />
        </Route>

        <Route
          exact
          path={
            subFolderName === null
              ? "/ResetPassword"
              : `/${subFolderName}/ResetPassword`
          }
        >
          <PasswordResetPage />
        </Route>

        <Route
          path={
            subFolderName === null
              ? "/content/:content_id"
              : `/${subFolderName}/content/:content_id`
          }
        >
          <Content />
        </Route>

        <Route
          path={
            subFolderName === null
              ? "/videoPage/:video_id"
              : `/${subFolderName}/videoPage/:video_id`
          }
        >
          <NewVideo />
        </Route>

        <Route path="*">
          <Error />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default RouteHandler;
