import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import { CompanyI, DivisionI } from "./NewVideo";

interface Props {
  company: any;
  division: any;
  companyList: CompanyI[];
  divisionList: DivisionI[];
  setCompany: any;
  setDivision: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    backgroundWhite: {
      backgroundColor: "white",
    },
  })
);

const VideoSelector = (props: Props) => {
  const classes = useStyles();

  const selectDivision = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log("selected division: ", event.target.value);
    props.setDivision(event.target.value as string);
  };

  const selectCompany = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log("selected company: ", event.target.value);
    props.setCompany(event.target.value as string);
  };

  return (
    <div className={classes.backgroundWhite}>
      <FormControl className={classes.formControl}>
        <InputLabel id="company-select">Company</InputLabel>
        <Select
          labelId="company-select"
          id="company-select"
          value={props.company}
          onChange={selectCompany}
        >
          {props.companyList.map((company) => (
            <MenuItem value={company.company_id} key={company.company_id}>
              {company.company_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel id="division-select">Division</InputLabel>
        <Select
          labelId="division-select"
          id="division-select"
          value={props.division}
          onChange={selectDivision}
        >
          {props.divisionList.map((division) => (
            <MenuItem value={division.division_id} key={division.division_id}>
              {division.division_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default VideoSelector;
