import React from "react";
import ToggleButton from "./ToggleButton";
import background from "../assets/images/toggle_panel_frame.png";

interface Props {
  isItemPresent: boolean;
  showCad: boolean;
  toggleCad: (input: boolean) => void;
  buttonText: string;
}

const ToggleButtonContainer = (props: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.toggleCad(event.target.checked);
  };

  if (!props.isItemPresent) {
    return null;
  }

  return (
    <div className="toggleButtonContainer">
      <img className="imageStyle" src={background} />
      <h1 className="textStyle">{props.buttonText}</h1>
      <div className="togglePlacement">
        <ToggleButton checked={props.showCad} onChange={handleChange} />
      </div>
    </div>
  );
};

export default ToggleButtonContainer;
