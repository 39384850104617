import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link, Redirect, useParams } from "react-router-dom";
import {
  getCompanies,
  getCompanyDivisionID,
  getCurrentUserDetails,
  getDivisions,
  getVideoLink,
} from "../api/api";
import { subFolderName } from "../Live360Config";

import VideoSelector from "./VideoSelector";

type Props = {};

type ContentState =
  | "loading"
  | "notPermittedForCurrUser"
  | "incorrectVideoUrl"
  | "success";

export interface LinkObjectI {
  name: string;
  videoURL: string;
  recommendURL: RecommendObjectI[];
}

interface RecommendObjectI {
  imgURL: string;
  hrefURL: string;
  suggTitle: string;
}

interface ResultI {
  jwtToken: string;
  sub: string;
  isMasterAccount: boolean;
}

export interface CompanyI {
  address: string;
  company_id: number;
  company_name: string;
  contract_end: string;
  contract_start: string;
  created_at: string;
  phone_number: string;
  trial_end: string;
  trial_start: string;
  updated_at: string;
}

export interface DivisionI {
  address: string;
  division_id: number;
  company_name: string;
  created_at: string;
  division_name: string;
  phone_number: string;
  updated_at: string;
}

const NewVideo = (props: Props) => {
  let { video_id } = useParams<{ video_id: string }>();
  // console.log("video_id:: ", video_id);

  const [contentState, setContentState] = useState<ContentState>("loading");
  const [isMasterAccount, setIsMasterAccount] = useState(false);
  const [totalDuration, setTotalDuration] = useState(0);
  const [playedDuration, setPlayedDuration] = useState(0);
  const [showRecommend, setShowRecommend] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [recommendURL1, setRecommendURL1] = useState<RecommendObjectI | null>(
    null
  );
  const [recommendURL2, setRecommendURL2] = useState<RecommendObjectI | null>(
    null
  );
  const [jwtToken, setJwtToken] = useState("");
  const [company, setCompany] = useState<string>("");
  const [division, setDivision] = useState<string>("");
  const [companyList, setCompanyList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);

  useEffect(() => {
    getCurrentUserDetails()
      .then(async (result: ResultI) => {
        if (result.isMasterAccount) {
          //if master account, save the JWT, request in a different useEffect
          console.log("master account 1");
          setIsMasterAccount(result.isMasterAccount);
          setJwtToken(result.jwtToken);
          const companyList = await getCompanies(result.jwtToken);
          setCompanyList(companyList.body.companyList);
          setContentState("success");
        } else {
          //if normal user, directly get the video link
          console.log(" normal account 1: ");
          const divisionList = await getCompanyDivisionID(
            result.jwtToken,
            result.sub
          );

          getActualVideoLink(result.jwtToken, divisionList[1]);
        }
      })
      .catch((err) => {
        console.log("video error: ", err);

        setContentState("notPermittedForCurrUser");
      });
  }, [video_id]);

  useEffect(() => {
    const fetchDivisions = async () => {
      console.log("Fetching divisions !!!!!!!!!");
      const divisionIdlist = await getDivisions(jwtToken, company);
      setDivisionList(divisionIdlist.body.divisionList);
    };

    if (division) {
      console.log("master account 2");
      getActualVideoLink(jwtToken, [division]);
    }

    if (company) {
      console.log("master account 3");
      fetchDivisions();
    }
  }, [company, division, jwtToken]);

  useEffect(() => {
    if (totalDuration !== 0 && totalDuration - playedDuration < 5) {
      setShowRecommend(true);
    }
  }, [totalDuration, playedDuration]);

  const getActualVideoLink = async (
    jwtToken: string,
    divisionList: string[]
  ) => {
    console.log("activating for master account? ", isMasterAccount);
    console.log("getting video");
    const videoLinkObject = await getVideoLink(
      jwtToken,
      video_id,
      divisionList
    );

    const data: LinkObjectI = videoLinkObject.body.videoInfo;
    // console.log("data: ", data);

    setVideoUrl(data.videoURL);
    setRecommendURL1(data.recommendURL[0]);
    setRecommendURL2(data.recommendURL[1]);
    setContentState("success");
  };

  const authStateUpdate = () => {
    setContentState("loading");
  };

  if (contentState === "loading") {
    return <h2 className="loadingText">loading...</h2>;
  } else if (
    contentState === "incorrectVideoUrl" ||
    contentState === "notPermittedForCurrUser"
  ) {
    return <Redirect to={"/"} />;
  } else {
    console.log("came in correct block", isMasterAccount);
    return (
      <div className="main">
        {isMasterAccount && (
          <VideoSelector
            company={company}
            division={division}
            companyList={companyList}
            divisionList={divisionList}
            setCompany={setCompany}
            setDivision={setDivision}
          />
        )}

        <ReactPlayer
          url={videoUrl}
          height={isMasterAccount ? "92%" : "100%"}
          width="100%"
          controls={true}
          onDuration={(duration) => {
            setTotalDuration(duration);
          }}
          onProgress={(progress) => {
            setPlayedDuration(progress.playedSeconds);
          }}
        />

        {showRecommend && (
          <>
            {recommendURL1 && (
              <Link
                to={
                  subFolderName === null
                    ? `/videoPage/${recommendURL1.hrefURL}`
                    : `/${subFolderName}/videoPage/${recommendURL1.hrefURL}`
                }
              >
                <div className="imgItem">
                  <img src={recommendURL1.imgURL} width="20%" height="auto" />
                </div>
              </Link>
            )}

            {recommendURL2 && (
              <Link
                to={
                  subFolderName === null
                    ? `/videoPage/${recommendURL2.hrefURL}`
                    : `/${subFolderName}/videoPage/${recommendURL2.hrefURL}`
                }
              >
                <div className="imgItem" style={{ top: "60%" }}>
                  <img src={recommendURL2.imgURL} width="20%" height="auto" />
                </div>
              </Link>
            )}
          </>
        )}
      </div>
    );
  }
};

export default NewVideo;
