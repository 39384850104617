import {
	ComponentInteractionType,
	Frame,
	GetSDK,
	IComponentEventSpy,
	IInteractionEvent,
	initComponents,
	ISceneNode,
	OrientedBox,
	orientedBoxType,
	SceneComponent,
	sdkKey,
	slotType,
} from "@mp/common";
import { cameraInputType } from "@mp/common/src/sdk-components/Camera";
import React, { Component } from "react";
import { ItemDesc } from "src/types";
import { Euler, Matrix4, Quaternion, Vector3 } from "three";
import { AppState } from "../AppState";
import { SceneLoader } from "../SceneLoader";

import { Link } from "react-router-dom";
import { live360DataBucket, subFolderName } from "../Live360Config";
import BackButton from "./BackButton";
import { ExternalFileList } from "./ExternalFileList";
import ToggleButtonContainer from "./ToggleButtonContainer";
import MiniMap from "./MiniMap";
import { Button } from "@material-ui/core";

const SelectedColor = 0xffff00;
const SelectedOpacity = 0.1;
const SelectedLineOpacity = 1.0;
const UnselectedColor = 0xffffff;
const UnselectedOpacity = 0.04;
const UnselectedLineOpacity = 0.4;

//var init for mini map function
let mapContainerSize_X = 500; //temporary
let mapContainerSize_Y = 500; //temporary
var minX = 9999;
var minZ = 9999;
var maxX = -9999;
var maxZ = -9999;

interface Props {
	appState: AppState;
}

interface State {
	slotNode: SlotNode | null;
	currAppStatus: any;
	isScenePresent: boolean;
	showCad: boolean;
	isMapPresent: boolean;
	showMap: boolean;
	isPDFPresent: boolean;
	showPDF: boolean;
	isBackButtonShown: boolean;
	isReady: boolean;
}

type SlotNode = {
	node: ISceneNode;
	slotComponent: SceneComponent;
	modelComponent: SceneComponent;
	boxComponent: OrientedBox;
};

export class Main extends Component<Props, State> {
	private sdk: any = null;
	private scene: SceneLoader = null;
	private slots: SlotNode[] = [];
	private cameraInput: SceneComponent;
	private src: string;
	private applicationKey: string;
	private allNodes: any[];

	constructor(props: Props) {
		super(props);

		this.state = {
			slotNode: null,
			currAppStatus: null,
			isScenePresent: false,
			showCad: true,
			isMapPresent: false,
			showMap: true,
			isPDFPresent: false,
			showPDF: true,
			isBackButtonShown: false,
			isReady: false,
		};

		// Forward url params.
		const params = objectFromQuery();
		params.m = params.m || this.props.appState.sid;
		params.play = params.play || "1";
		params.qs = params.qs || "1";
		if (
			this.props.appState.initialState_sr != null &&
			this.props.appState.initialState_ss != null
		) {
			params.sr = params.sr || this.props.appState.initialState_sr;
			params.ss = params.ss || this.props.appState.initialState_ss;
		}
		// params.sr = params.sr || this.props.appState.initialState_sr; //dont assign "sr" if it is not provided by the cms response which is passed down from "Content.tsx" via props. Do not uncomment. Useful for local dev test
		// params.ss = params.ss || this.props.appState.initialState_ss; //dont assign "ss" if it is not provided by the cms response which is passed down from "Content.tsx" via props. Do not uncomment. Useful for local dev test
		// params.sr = params.sr || '0.0,3.0';      //hardcoded,not necessary,just here for reference and test,do not remove
		// params.ss = params.ss || '82';           //hardcoded,not necessary,just here for reference and test,do not remove
		//ensure applicationKey is inserted into the bundle query string
		params.applicationKey = params.applicationKey || sdkKey;
		this.applicationKey = params.applicationKey;

		const queryString = Object.keys(params)
			.map((key) => key + "=" + params[key])
			.join("&");
		// this.src = `./bundle/showcase.html?${queryString}`;//Original Line
		this.src =
			subFolderName === null
				? `/bundle/showcase.html?${queryString}`
				: `/${subFolderName}/bundle/showcase.html?${queryString}`; // when project is hosted inside a folder within S3 bucket instead of the root of S3

		this.handleListSelection = this.handleListSelection.bind(this);
		this.toggleCad = this.toggleCad.bind(this);
		this.togglePDF = this.togglePDF.bind(this);
		this.toggleMap = this.toggleMap.bind(this);
	}

	async componentDidMount() {
		this.sdk = await GetSDK("sdk-iframe", this.applicationKey);
		const SDKREF = this.sdk;
		await initComponents(this.sdk);
		await this.createCameraControl(this.sdk);
		await this.sdk.Scene.configure((renderer: any, three: any) => {
			renderer.physicallyCorrectLights = true;
			renderer.gammaFactor = 2.2;
			renderer.gammaOutput = true;
			renderer.shadowMap.enabled = true;
			renderer.shadowMap.bias = 0.0001;
			renderer.shadowMap.type = three.PCFSoftShadowMap;
			renderer.outputEncoding = three.sRGBEncoding;
		});
		console.log(this.props.appState.scenarioJson);
		this.scene = new SceneLoader(this.sdk, this.props.appState.scenarioJson);

		const slots: SlotNode[] = [];
		const allnodes: ISceneNode[] = [];

		// let tst = this.sdk;
		this.sdk.App.state.subscribe(
			function (appState: any) {
				//console.log("CURR APP STATE:::Main.tsx::", appState.phase)
				// this.setState({ currAppStatus: appState.phase })

				// if (appState.phase === this.sdk.App.Phase.LOADING) {
				//   console.log('The app has started loading!')
				// }
				// if (appState.phase === this.sdk.App.Phase.STARTING) {
				//   console.log('The transition into the start location begins!')
				// }
				// if (appState.phase === this.sdk.App.Phase.PLAYING) {
				//   console.log('The app is ready to take user input now!')
				// }
				if (appState.phase === SDKREF.App.Phase.PLAYING) {
					this.setState({ currAppStatus: "PLAYING" });
					console.log("The app is ready to take user input now!");

					document
						.getElementById("copyrightText_hidden")
						.setAttribute("id", "copyrightText");
				}
			}.bind(this)
		);

		this.setState({
			isReady: true,
		});

		class ClickSpy implements IComponentEventSpy<IInteractionEvent> {
			public eventType = ComponentInteractionType.CLICK;
			constructor(private mainComponent: Main) {}
			onEvent(payload: IInteractionEvent) {
				this.mainComponent.handleOrientedBoxInteraction(
					payload.node,
					payload.component,
					payload.type
				);
			}
		}

		class HoverSpy implements IComponentEventSpy {
			public eventType = ComponentInteractionType.HOVER;
			constructor(private mainComponent: Main) {}
			onEvent(payload: { hover: boolean }) {
				this.mainComponent.cameraInput.inputs.suppressClick = !payload.hover;
			}
		}

		const clickSpy = new ClickSpy(this);
		const hoverSpy = new HoverSpy(this);
		const findSlots = (node: ISceneNode) => {
			let slot: SceneComponent = null;
			let model: SceneComponent = null;
			let box: OrientedBox = null;
			const componentIterator: IterableIterator<SceneComponent> =
				node.componentIterator();
			for (const component of componentIterator) {
				if (component.componentType === slotType) {
					slot = component;
				} else if (component.componentType === "mp.gltfLoader") {
					model = component;
				} else if (component.componentType == orientedBoxType) {
					box = component as OrientedBox;
					box.spyOnEvent(clickSpy);
					box.spyOnEvent(hoverSpy);
					box.inputs.color = UnselectedColor;
					box.inputs.opacity = UnselectedOpacity;
				}
			}

			allnodes.push(node);

			if (slot && model) {
				slots.push({
					node: node,
					slotComponent: slot,
					modelComponent: model,
					boxComponent: box,
				});
			}
		};

		this.slots = slots;
		// await this.scene.load('rNG882oSGVz', findSlots);
		await this.scene.load(`${this.props.appState.sid}`, findSlots);

		this.allNodes = allnodes;
		console.log(this.scene);
		console.log(this.sdk);

		if (this.checkCad()) {
			this.setState({
				isScenePresent: true,
			});
		}

		if (!(this.props.appState.miniMapURL === "")) {
			this.setState({
				isMapPresent: true,
			});
		}

		if (!(this.props.appState.externalDocJSON === "")) {
			this.setState({
				isPDFPresent: true,
			});
		}

		this.setState({
			isBackButtonShown: true,
		});
	}

	private handleListSelection(item: ItemDesc) {
		const slotNode = this.state.slotNode;
		if (!slotNode) {
			return;
		}
		slotNode.slotComponent.inputs.model = item.url;
		slotNode.modelComponent.inputs.localPosition.x = item.position.x;
		slotNode.modelComponent.inputs.localPosition.y = item.position.y;
		slotNode.modelComponent.inputs.localPosition.z = item.position.z;
		slotNode.modelComponent.inputs.localRotation.x = item.rotation.x;
		slotNode.modelComponent.inputs.localRotation.y = item.rotation.y;
		slotNode.modelComponent.inputs.localRotation.z = item.rotation.z;
		slotNode.modelComponent.inputs.localScale.x = item.scale.x;
		slotNode.modelComponent.inputs.localScale.y = item.scale.y;
		slotNode.modelComponent.inputs.localScale.z = item.scale.z;
	}

	private handleOrientedBoxInteraction(
		node: ISceneNode,
		component: SceneComponent,
		interactionType: ComponentInteractionType
	) {
		if (interactionType === ComponentInteractionType.CLICK) {
			// select this node
			for (const slot of this.slots) {
				if (slot.node === node) {
					for (const componentSearch of node.componentIterator()) {
						if (componentSearch === component) {
							const lastSlotNode = this.state.slotNode;
							if (lastSlotNode) {
								lastSlotNode.boxComponent.inputs.color = UnselectedColor;
								lastSlotNode.boxComponent.inputs.opacity = UnselectedOpacity;
								lastSlotNode.boxComponent.inputs.lineOpacity =
									UnselectedLineOpacity;
							}

							if (lastSlotNode === slot) {
								this.cameraInput.inputs.focus = null;

								this.setState({
									slotNode: null,
								});
							} else {
								this.setState({
									slotNode: slot,
								});

								slot.boxComponent.inputs.color = SelectedColor;
								slot.boxComponent.inputs.opacity = SelectedOpacity;
								slot.boxComponent.inputs.lineOpacity = SelectedLineOpacity;
								this.cameraInput.inputs.focus = node.position;
							}
						}
					}
				}
			}
		}
	}

	private toggleCad(input: boolean) {
		this.setState({
			showCad: input,
		});

		// item.components[0].instance.componentType === "mp.gltfLoader"

		this.allNodes.forEach((item) => {
			const componentsIterator = [...item.componentIterator()];
			for (const component of componentsIterator) {
				if (
					component &&
					(component.componentType === "mp.gltfLoader" ||
						component.componentType === "mp.daeLoader" ||
						component.componentType === "mp.fbxLoader" ||
						component.componentType === "mp.objLoader")
				) {
					item.obj3D.visible = input;
				}
			}
		});
	}

	private togglePDF(input: boolean) {
		this.setState({
			showPDF: input,
		});
	}

	private toggleMap(input: boolean) {
		this.setState({
			showMap: input,
		});
	}

	private checkCad(): boolean {
		let isCadPresent = false;
		this.allNodes.forEach((item) => {
			console.log("item:", item);
			// item: {}
			const componentsIterator = [...item.componentIterator()];
			for (const component of componentsIterator) {
				console.log("component:", component);
				// component: {xxxx,xxxx,,xxxxx}
				if (
					component &&
					(component.componentType === "mp.gltfLoader" ||
						component.componentType === "mp.daeLoader" ||
						component.componentType === "mp.fbxLoader" ||
						component.componentType === "mp.objLoader")
				) {
					isCadPresent = true;
				}
			}
		});

		return isCadPresent;
	}

	render() {
		return (
			<div className="main" id="main">
				{!this.state.isPDFPresent ||
				this.state.currAppStatus != "PLAYING" ? null : this.state.showPDF ? (
					<ExternalFileList
						externalDocList={this.props.appState.externalDocJSON}
						SDK_REF={this.sdk}
					/>
				) : null}

				{this.state.isReady && this.state.isMapPresent && (
					<MiniMap
						miniMapRatio_X={this.props.appState.miniMapRatio_X}
						miniMapRatio_Y={this.props.appState.miniMapRatio_Y}
						minimapUrl={this.props.appState.miniMapURL}
						miniMapRotation={this.props.appState.miniMapRotation}
						sdk={this.sdk}
						showMiniMap={this.state.showMap}
					/>
				)}

				<div className="buttonContainer">
					<div className="buttonItem">
						<ToggleButtonContainer
							buttonText="MAP"
							isItemPresent={this.state.isMapPresent}
							showCad={this.state.showMap}
							toggleCad={this.toggleMap}
						/>
					</div>

					<div className="buttonItem">
						<ToggleButtonContainer
							buttonText="PDF"
							isItemPresent={this.state.isPDFPresent}
							showCad={this.state.showPDF}
							toggleCad={this.togglePDF}
						/>
					</div>

					<div className="buttonItem">
						<ToggleButtonContainer
							buttonText="CAD"
							isItemPresent={this.state.isScenePresent}
							showCad={this.state.showCad}
							toggleCad={this.toggleCad}
						/>
					</div>

					<Link to={subFolderName === null ? "/" : `/${subFolderName}`}>
						<div className="buttonItem">
							<BackButton isBackButtonShown={this.state.isBackButtonShown} />
						</div>
					</Link>
				</div>
				<Frame src={this.src}></Frame>
				<p id="copyrightText_hidden">© 2022 Premium ARTS Inc. | </p>
			</div>
		);
	}

	async createCameraControl(theSdk: any) {
		const cameraNode = await theSdk.Scene.createNode();
		const cameraPose = await theSdk.Camera.getPose();
		this.cameraInput = cameraNode.addComponent(cameraInputType);
		// convert sdk pose to THREE.js objects
		this.cameraInput.inputs.startPose = {
			position: new Vector3(
				cameraPose.position.x,
				cameraPose.position.y,
				cameraPose.position.z
			),
			quaternion: new Quaternion().setFromEuler(
				new Euler(
					(cameraPose.rotation.x * Math.PI) / 180,
					(cameraPose.rotation.y * Math.PI) / 180,
					((cameraPose.rotation.z || 0) * Math.PI) / 180,
					"YXZ"
				)
			),
			projection: new Matrix4().fromArray(cameraPose.projection).transpose(),
		};
		const cameraControl = cameraNode.addComponent("mp.camera");
		cameraControl.bind("camera", this.cameraInput, "camera");

		cameraNode.start();
	}
}

// from cwf/modules/browser.ts
export const objectFromQuery = (url?: string): { [key: string]: string } => {
	const regex = /[#&?]([^=]+)=([^#&?]+)/g;
	url = url || window.location.href;
	const object: { [param: string]: string } = {};
	let matches;
	// regex.exec returns new matches on each
	// call when we use /g like above
	while ((matches = regex.exec(url)) !== null) {
		object[matches[1]] = decodeURIComponent(matches[2]);
	}
	return object;
};
