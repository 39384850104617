import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { Object3D, AnimationMixer, AnimationAction, LoopOnce, AnimationClip, Mesh, Texture, MeshLambertMaterial } from 'three';
import { IPainter2d } from './CanvasRenderer';
import { PlaneRenderer, Size } from './PlaneRenderer';

const HoverEvent = 'hover';
const UnhoverEvent = 'unhover';
const RepaintEvent = 'repaint';

type IOTData = {
  time: string;
  value: number;	
}

type Inputs = {
  loadingState: string;
  texture: Texture | null;
  updateInterval: number;
  minVal: number;
  maxVal: number;
  minWarningVal:number;
  maxWarningVal:number;
  unit: string;
  data: IOTData[];
  URL: string;
  dataRow: number;
  dataFieldName: string;
}

type Outputs = {
  painter: IPainter2d | null;
  visible: boolean;
} & ComponentOutput;


class IotVisualizer extends SceneComponent implements IPainter2d {
  private daeComponent: SceneComponent;
  private mixer: AnimationMixer | null = null;
  private onEnterClip: AnimationClip | null = null;
  private mesh: Mesh | null = null;
  private currentTime: number = 0;
  private nextUpdate: number = 0;
  private dataValue: number = 0;
//  private tempCounter: number = 0;
//  private tempChangeRange: number = 0;

  inputs: Inputs = {
    loadingState: 'Idle',
    texture: null,
    updateInterval: 1000,
    minVal: 0,
    maxVal: 1,
    minWarningVal:0,
    maxWarningVal:1,
    unit: "x",
    data: null,
    URL:"x",
    dataRow: 0,
    dataFieldName:"x"
  }

  outputs = {
    painter: null,
    visible: false,
  } as Outputs;

  events = {
    [HoverEvent]: true,
    [UnhoverEvent]: true,
  };

  onInit() {
    const root = this.context.root;
    const THREE = this.context.three;

    let planeRenderer: PlaneRenderer;
    for (const component of root.componentIterator()) {
      if (component.componentType === 'mp.daeLoader') {
        this.daeComponent = component;
      }
      else if (component.componentType === 'mp.planeRenderer') {
        planeRenderer = component as PlaneRenderer;
        planeRenderer.outputs.objectRoot.translateZ(0.05);
        planeRenderer.outputs.objectRoot.translateY(0.4);
        planeRenderer.outputs.objectRoot.scale.set(0.5, 0.5, 0.5);
      }
    }


    this.outputs.painter = this;

    this.mixer = new THREE.AnimationMixer(planeRenderer.outputs.objectRoot);

    const tm = 0.2;
    const positionTrack = new THREE.VectorKeyframeTrack('.scale', [0, tm], [
      0, 0, 0,
      0.5, 0.5, 0.5
    ], THREE.InterpolateSmooth);
    this.onEnterClip = new THREE.AnimationClip(null, tm, [positionTrack]);
  }

  onInputsUpdated() {
    const THREE = this.context.three;
    if (this.inputs.loadingState === 'Loaded') {
      this.daeComponent.outputs.objectRoot.traverse((obj: Object3D) => {
        // we dont want line segments
        if (obj.type === 'LineSegments') {
          obj.visible = false;
        }
        else if (obj.type === 'Mesh') {
          this.mesh = obj as Mesh;

          const material = this.mesh.material as MeshLambertMaterial;
          if (material && material.name === '_5b76dbe388862300126c1e14') {
            const newMaterial = new THREE.MeshBasicMaterial({ map: this.inputs.texture });
            this.mesh.material = newMaterial;
          }
        }
      });
    }
  }

  onEvent(eventType: string, eventData: unknown): void {
    if (eventType === HoverEvent) {
      const data: any = eventData;
      if (data.hover) {
        this.outputs.visible = true;
        const onEnterAction: AnimationAction = this.mixer.clipAction(this.onEnterClip);
        onEnterAction.stop();
        onEnterAction.loop = LoopOnce;
        onEnterAction.clampWhenFinished = true;
        onEnterAction.play();
      }
      else {
        this.outputs.visible = false;
      }
    }
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    const x = 490;
    const y = 490;

    context2d.fillStyle = 'black';
    context2d.beginPath();
    context2d.arc(x, y, 350, 0, Math.PI * 2);
    context2d.fill();

    context2d.fillStyle = '#79B751';
    if(this.dataValue<this.inputs.minWarningVal){
	    context2d.fillStyle = 'red';
    }
    if(this.dataValue>this.inputs.maxWarningVal){
	    context2d.fillStyle = 'red';
    }
    context2d.beginPath();
    context2d.arc(x, y, 300, 0, Math.PI * 2);
    context2d.fill();

    context2d.beginPath();
    context2d.strokeStyle = 'orange';
    //context2d.arc(x, y, 240, 0 * Math.PI+0.75*Math.PI, (((this.dataValue-this.inputs.minVal)*(2.25*Math.PI-0.75*Math.PI))/(this.inputs.maxVal-this.inputs.minVal))+0.75*Math.PI,false);
    context2d.arc(x, y, 240, 0 * Math.PI+1.00*Math.PI, (((this.dataValue-this.inputs.minVal)*(2.00*Math.PI-1.00*Math.PI))/(this.inputs.maxVal-this.inputs.minVal))+1.00*Math.PI,false);
    context2d.lineCap = 'butt';
    context2d.lineWidth = 80;
    context2d.stroke();

    context2d.fillStyle = 'white';
    context2d.font = '140px Arial';
    context2d.fillText(`${this.dataValue}`, x-0, y+35);

    context2d.fillStyle = 'white';
    context2d.font = '110px Arial';
    context2d.fillText(`${this.inputs.unit}`, x-0, y+250);
    context2d.textAlign = 'center';
    
    context2d.fillStyle = 'white';
    context2d.font = '110px Arial';
    context2d.fillText(`${this.inputs.minVal}`, x-200, y+120);
    context2d.textAlign = 'center';

    context2d.fillStyle = 'white';
    context2d.font = '110px Arial';
    context2d.fillText(`${this.inputs.maxVal}`, x+200, y+120);
    context2d.textAlign = 'center';
  }

  onTick(delta: number) {
    this.currentTime += delta;

    if (this.mixer) {
      this.mixer.update(delta/1000);
    }

    if (this.currentTime > this.nextUpdate) {
      this.nextUpdate += this.inputs.updateInterval;

//      this.temperature += (Math.random() * this.tempChangeRange);
//      this.temperature = Math.trunc(this.temperature);
//
//      if (this.temperature > 99) {
//       // this.temperature = 99;
//        this.tempChangeRange = -this.tempChangeRange;
//      }
//      if (this.temperature < 10) {
//       // this.temperature = 10;
//        this.tempChangeRange = -this.tempChangeRange;
//      }


	//console.log("minVal: "+this.inputs.minVal+" maxVal: "+this.inputs.maxVal);


//	if (this.inputs.data) {
//		console.log("data: ", this.inputs.data);
//		console.log("tempCounter: ", this.tempCounter);
//		this.dataValue = 0.0;
//		this.dataValue = this.inputs.data[this.tempCounter%this.inputs.data.length].value;
//		this.tempCounter++;
//	} else {
//	        this.dataValue = ((Math.random() * (this.inputs.maxVal-this.inputs.minVal))+this.inputs.minVal);
//	        this.dataValue = Math.round(this.dataValue*100)/100;
//	}


      let request = new XMLHttpRequest();
      //let URL:string ="https://jsonplaceholder.typicode.com/todos/"+Math.trunc(Math.random()*200);
      //let URL:string ="https://takeda-dev.cloud.thingworx.com/Thingworx/Things/RESTAPI_TestThing/Properties/OS_G_9TB_PROD_STL_A4500_NA_ST001";
      request.open("GET", this.inputs.URL);

      request.setRequestHeader('Accept', 'application/json');
      request.setRequestHeader('Content-Type', 'application/json');
      request.setRequestHeader('appKey', '9bc5f4be-7634-439b-97d2-062aa2ef0dad');

      request.send();
      request.onload = () => {
        //console.log(request);
        if (request.status === 200) {
          // by default the response comes in the string format, we need to parse the data into JSON

          var parseData = JSON.parse(request.response);
          if (parseData && parseData.rows[this.inputs.dataRow] && parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName] != null) {
              // console.log("Only the data field .rows[0][] : "+(parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName]));
	            this.dataValue=Number(parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName].toFixed(2));
              // console.log("Value of dataValue: "+this.dataValue);
          }
          //console.log(Number(JSON.parse(request.response).row[this.dataRow].fieldName));
          //this.temperature = Number(JSON.parse(request.response).internalTemperatureTop);
          //console.log(this.temperature);
          //console.log("minVal: "+this.inputs.minVal+" maxVal: "+this.inputs.maxVal);
        } else {
	        console.log("LOL IT NO WORK GG");
          console.log(`error ${request.status} ${request.statusText}`);
        }
      };



      this.notify(RepaintEvent);
    }
  }
}

export const IotVisualizerType = 'mp.IotVisualizer';
export const makeIotVisualizer = function() {
  return new IotVisualizer();
}

