import { Auth } from "aws-amplify";
import { API_URL } from "../Live360Config";

export const getCurrentUserJwtToken = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((sessionData) => {
        let jwtToken: string = sessionData.getIdToken().getJwtToken();
        resolve(jwtToken);
      })
      .catch((err) => {
        reject("no-user");
      });
  });
};

export const contentPermittedForCurrUser = (
  content_id: string,
  jwtToken: string
) => {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    let URL =
      `${API_URL}/content/selectforupdate?` + "content_id=" + content_id;
    xhr.open("GET", URL);

    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", jwtToken);

    xhr.onload = () => {
      var parsedResponse = JSON.parse(xhr.responseText);
      if (JSON.parse(xhr.responseText)["statusCode"] != 200) {
        resolve(["notPermittedForCurrUser", "", "", "", "", ""]);
      } else {
        resolve([
          parsedResponse.body["contentInfo"]["space_id"],
          parsedResponse.body["contentInfo"]["scenario_json"],
          parsedResponse.body["contentInfo"]["external_document_json"],
          parsedResponse.body["contentInfo"]["map_image"],
          parsedResponse.body["contentInfo"]["map_detail_json"],
          parsedResponse.body["contentInfo"]["initial_state_json"],
        ]);
      }
    };
    xhr.send();
  });
};
