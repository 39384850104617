import * as React from 'react';
import { useLocation } from "react-router-dom"

export interface IErrorProps {
}

function Error(props: IErrorProps) {
  const location = useLocation()
  return (
    <div>
      <p>{location.pathname}</p>
      <p>404/403</p>
    </div>
  );
}

export default Error