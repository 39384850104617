//make sure to update "webpack.config.js" file in the vs-app folder if
//any change is made to "subFolderName" const.
export const subFolderName: string | null = null;

export const API_URL = process.env.API_URL;
export const live360DataBucket = process.env.LIVE_360_DATA_BUCKET_URL;
export const AWS_IDENTITY_POOL_ID = process.env.AWS_IDENTITY_POOL_ID;
export const AWS_REGION = process.env.AWS_REGION;
export const AWS_USER_POOL_ID = process.env.AWS_USER_POOL_ID;
export const AWS_POOL_WEB_CLIENT_ID = process.env.AWS_POOL_WEB_CLIENT_ID;
export const AWS_COOKIE_DOMAIN = process.env.AWS_COOKIE_DOMAIN;
export const NODE_ENV = process.env.NODE_ENV;

//------------------BELOW ARE JUST FOR DEV TIME TEST ONLY-----------------
// export const miniMapEnabledSpace: any =
// {
//   "rNG882oSGVz": {
//     "URL": "https://s3.ap-northeast-1.amazonaws.com/imgs.cont/takeda_6thFloor_map_v01.png",
//     "ratio_X": 0.85,
//     "ratio_Y": 0.87
//   },
//   "JRd35gvYwgz": {
//     "URL": "https://s3.ap-northeast-1.amazonaws.com/imgs.cont/takeda_7thFloor_map_v01.png",
//     "ratio_X": 0.87,
//     "ratio_Y": 0.87
//   }
// }

// export const externalDocLinkJSON = {
//   "linkDetails": [
//     {
//       "header": "製品仕様書​",
//       "links": [
//         {
//           "linkHeader": "【製品仕様】EX-NO1A.pdf",
//           "link": "https://hitachi-assets.live-xr.com/Document/modelplant/(2)%E3%80%90%E8%A3%BD%E5%93%81%E4%BB%95%E6%A7%98%E3%80%91EX-NO1A.pdf",
//           "sweep": "f4f7d07fddec459ba9fd25bf8eed9ec3",
//           "sweepRot": { "x": -20, "y": -90 }

//         },
//         {
//           "linkHeader": "【製品仕様】AN700A.pdf",
//           "link": "https://hitachi-assets.live-xr.com/Document/modelplant/(4)%E3%80%90%E8%A3%BD%E5%93%81%E4%BB%95%E6%A7%98%E3%80%91AN700A.pdf",
//           "sweep": "18254eaf181348bdaa5ab01db2dd1095",
//           "sweepRot": { "x": -10, "y": -85 }
//         },
//         {
//           "linkHeader": "【製品仕様】伝送器.pdf",
//           "link": "https://hitachi-assets.live-xr.com/Document/modelplant/(7)%E3%80%90%E8%A3%BD%E5%93%81%E4%BB%95%E6%A7%98%E3%80%91%E4%BC%9D%E9%80%81%E5%99%A8.pdf",
//           "sweep": "e30b0815339f42099f68d7898d6f022a",
//           "sweepRot": { "x": 0, "y": -20 }
//         }
//       ]
//     },
//     {
//       "header": "検査成績表​",
//       "links": [
//         {
//           "linkHeader": "(11/12実施)DCS検査成績書.pdf",
//           "link": "https://hitachi-assets.live-xr.com/Document/modelplant/(1)(21.11.12%E5%AE%9F%E6%96%BD)DCS%E6%A4%9C%E6%9F%BB%E6%88%90%E7%B8%BE%E6%9B%B8.pdf",
//           "sweep": "f4f7d07fddec459ba9fd25bf8eed9ec3",
//           "sweepRot": { "x": -20, "y": -30 }
//         },
//         {
//           "linkHeader": "(11/12実施)水質計検査成績書.pdf",
//           "link": "https://hitachi-assets.live-xr.com/Document/modelplant/(6)(21.11.12%E5%AE%9F%E6%96%BD)%E6%A4%9C%E6%9F%BB%E6%88%90%E7%B8%BE%E6%9B%B8.pdf",
//           "sweep": "5af13b57e53d4777aa30fbfcb1bce277",
//           "sweepRot": { "x": -10, "y": -65 }
//         }
//       ]
//     },
//     {
//       "header": "その他",
//       "links": [
//         {
//           "linkHeader": "計装フローシート.pdf",
//           "link": "https://hitachi-assets.live-xr.com/Document/modelplant/(8)%E8%A8%88%E8%A3%85%E3%83%95%E3%83%AD%E3%83%BC%E3%82%B7%E3%83%BC%E3%83%88.pdf",
//           "sweep": "0d1afcfd95cb455daee139e8def133ac",
//           "sweepRot": { "x": 0, "y": 0 }
//         },
//         {
//           "linkHeader": "プラント構造図.pdf",
//           "link": "https://hitachi-assets.live-xr.com/Document/modelplant/(9)%E3%83%97%E3%83%A9%E3%83%B3%E3%83%88%E6%A7%8B%E9%80%A0%E5%9B%B3.pdf",
//           "sweep": "a6f429c52977463cbdbb395256a49f71",
//           "sweepRot": { "x": 10, "y": -100 }
//         }
//       ]
//     }
//   ]
// }
