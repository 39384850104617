import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Main } from "./Main";
import { AppState, items, slots } from "../AppState";
import { Auth } from "aws-amplify";

import { API_URL } from "../Live360Config";
import LoginPage from "./LoginPage";
import {
  contentPermittedForCurrUser,
  getCurrentUserJwtToken,
} from "../util/authUtils";

const appState = new AppState();
appState.items = items;
appState.slots = slots;

let scenarioJson = "";
let externalDataJSONJson = "";
let miniMapImg = "";
let miniMapImgRatio_X = "";
let miniMapImgRatio_Y = "";
let miniMapRotation = "";
let initialState_ss = "1";
let initialState_sr = "0.0,0.0";

function Content() {
  const [contentState, setcontentState] = useState<string>("loading");

  let { content_id } = useParams<{ content_id: string }>();

  useEffect(() => {
    getCurrentUserJwtToken()
      .then((jwttoken: string) => {
        contentPermittedForCurrUser(content_id, jwttoken).then(
          (result: string) => {
            console.log("Result: ", result);
            scenarioJson = result[1];
            externalDataJSONJson = result[2];
            miniMapImg = result[3];
            if (result[4] != "") {
              miniMapImgRatio_X = JSON.parse(result[4]).ratio_X;
              miniMapImgRatio_Y = JSON.parse(result[4]).ratio_Y;
              miniMapRotation=JSON.parse(result[4]).rotation==null?0:JSON.parse(result[4]).rotation;
            }
            if (result[5] != "") {
              initialState_ss = JSON.parse(result[5]).start_position;
              initialState_sr = JSON.parse(result[5]).start_rotation;
            } else {
              initialState_ss = null;
              initialState_sr = null;
            }
            setcontentState(result[0]);
          }
        );
      })
      .catch((err) => {
        setcontentState("notPermitted");
      });
  });

  const authStateUpdate = () => {
    setcontentState("loading");
  };

  if (contentState === "loading") {
    return <h2 className="loadingText">loading...</h2>;
  } else if (contentState === "notPermitted") {
    return (
      <LoginPage
        from="Content"
        content_ID={content_id}
        loginSuccess={() => authStateUpdate()}
      />
    );
  } else if (contentState === "notPermittedForCurrUser") {
    return (
      <LoginPage
        from="Content"
        instructionMessage="Not authorized to view requested content, Login using correct account"
        content_ID={content_id}
        loginSuccess={() => authStateUpdate()}
      />
    );
  } else {
    appState.sid = contentState;
    appState.scenarioJson = scenarioJson;
    appState.externalDocJSON = externalDataJSONJson;
    appState.miniMapURL = miniMapImg;
    if (!(miniMapImg === "")) {
      appState.miniMapRatio_X = parseFloat(miniMapImgRatio_X);
      appState.miniMapRatio_Y = parseFloat(miniMapImgRatio_Y);
      appState.miniMapRotation = parseFloat(miniMapRotation);
    }
    appState.initialState_ss = initialState_ss;
    appState.initialState_sr = initialState_sr;
    return <Main appState={appState} />;
  }
}

export default Content;
