import React from "react";
import { useRef, useState } from "react"
import { useHistory,Link } from 'react-router-dom';
import LiveXR360_bv from "../assets/images/LiveXR360_bv.png";
import { Auth } from 'aws-amplify';

import { subFolderName } from "../Live360Config"

export interface LoginPageProps {
  from: string
  instructionMessage?: string
  content_ID?: string
  loginSuccess: () => void
}

export interface LoginPageState {

}

function LoginPage(props: LoginPageProps) {
  const [login_state, setlogin_state] = useState("notAttempted")
  const history = useHistory();
  const inputRef_userName = useRef(null);
  const inputRef_password = useRef(null);

  const signIn = async () => {
    try {
      await Auth.signIn(inputRef_userName.current.value, inputRef_password.current.value).then((res) => {
        props.loginSuccess();
        let path
        if (props.from === "/") {
          path = subFolderName === null ? "/" : `/${subFolderName}`
        }
        else if (props.from === "ContentListPage") {
          path = subFolderName === null ? "/" : `/${subFolderName}`
        }
        else if (props.from === "Content") {
          path = subFolderName === null ? `/content/${props.content_ID}` : `/${subFolderName}/content/${props.content_ID}`
        }
        history.push(path);
      });
    } catch (error) {
      setlogin_state("wrongCredential")
    }
  }

  return (
    <div className="login">
      <div className="userDetailSection">
        <img id="loginPageLogo" src={LiveXR360_bv} alt="" />
        <div className="loginHeader">
          <p>Login to your web app</p>
          <p>Use user name to sign in</p>
        </div>
        <div className="form">
          <div id="usernameHeader">
            <p>Username</p>
          </div>
          <div className="credentialContainer">
            <input ref={inputRef_userName} type="text" placeholder="Username" id="name" className="inputField" />
          </div>

          <div id="passwordHeader">
            <p>Password</p>
          </div>
          <div className="credentialContainer">
            <input ref={inputRef_password} type="password" placeholder="Password" id="password" className="inputField" />
          </div>
        </div>
        <button type="submit" id="login-button" onClick={signIn}>Log In</button>
        <div id="passwordResetDiv">
          <p>Forgot your password?</p>
          <Link to={subFolderName === null ? `/ResetPassword` : `/${subFolderName}/ResetPassword`}>Reset Password</Link>
          {/* <p>Reset Password</p> */}
        </div>
      </div>
      <div className={login_state === "wrongCredential" ? "errorMessageDiv" : "hidden"}>
        <p className={login_state === "wrongCredential" ? "errorMessageVisible" : "hidden"}>wrong User name or password</p>
        <p className={props.instructionMessage === undefined ? "hidden" : "errorMessageVisible"}>{props.instructionMessage}</p>
      </div>
    </div>
  )
}

export default LoginPage