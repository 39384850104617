import React from 'react';
import { subFolderName } from "../Live360Config"
import { live360DataBucket } from "../Live360Config"

import { Link } from "react-router-dom"
type SpaceBlockProps = {
  spaceBlockDetails: any;
  displayStyle: string
}

function SpaceBlock(props: SpaceBlockProps) {
  if (props.displayStyle == "grid") {
    return (
      <Link className={"spaceBlock_GRID"} to={subFolderName === null ? `/content/${props.spaceBlockDetails["content_id"]}` : `/${subFolderName}/content/${props.spaceBlockDetails["content_id"]}`}>
        <img className='spaceBlockImage_GRID' src={`${live360DataBucket}/` + props.spaceBlockDetails["image"]} alt="" />

        <div id='spaceBlockFooter_GRID'>
          <div id='spaceBlockTitleContainer_GRID'>
            <h4>{props.spaceBlockDetails["title"]}</h4>
          </div>
        </div>
      </Link>
    );
  }
  else {
    return (
      <Link className={"spaceBlock_LIST"} to={subFolderName === null ? `/content/${props.spaceBlockDetails["content_id"]}` : `/${subFolderName}/content/${props.spaceBlockDetails["content_id"]}`}>
        <img className='spaceBlockImage_LIST' src={`${live360DataBucket}/` + props.spaceBlockDetails["image"]} alt="" />
        <div id='spaceBlockFooter_LIST'>
          <div id='title_LIST'>
            <h4>{props.spaceBlockDetails["title"]}</h4>
          </div>

          <div id='subtitle_LIST'>
            <h4>{props.spaceBlockDetails["subtitle"]}</h4>
          </div>
        </div>
      </Link>
    );
  }
}

export default SpaceBlock