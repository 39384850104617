import React, { useRef, useEffect, useState } from "react";
import { live360DataBucket } from "../Live360Config";
import MapFrame from "../assets/images/map_frame.png";

type Props = {
  showMiniMap: boolean;
  minimapUrl: string;
  miniMapRatio_X: number;
  miniMapRatio_Y: number;
  miniMapRotation: number;
  sdk: any;
};

interface PointData {
  id: string;
  left: number;
  top: number;
}

const MiniMap = (props: Props) => {
  const viewConeRef = useRef(null);
  const currentLocMarkerRef = useRef(null);
  const mapRef = useRef(null);
  let minX = 9999;
  let minZ = 9999;
  let maxX = -9999;
  let maxZ = -9999;
  const mapAllContainerSize_X = 500;
  const mapAllContainerSize_Y = 500;
  const pointMarkerWidth = 30;
  const pointMarkerHeight = 30;
  const [mapPoints, setMapPoints] = useState([]);

  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapWidth, setMapWidth] = useState(1);
  const [mapHeight, setMapHeight] = useState(1);


  useEffect(() => {
    if (viewConeRef.current && currentLocMarkerRef) {
      props.sdk.Camera.pose.subscribe(function (pose: any) {
        if (viewConeRef.current !== null && currentLocMarkerRef !== null) {
          viewConeRef.current.style.transform =
            "rotate(" + -1 * pose.rotation.y + "deg)";
          currentLocMarkerRef.current.style.transform =
            "rotate(" + -1 * pose.rotation.y + "deg)";
        }
      });
    }
  }, [viewConeRef.current]);

  useEffect(() => {
    console.log("sdksss: ", props.sdk);
    if (mapRef.current) {
      let pointsArray: PointData[] = [];
      props.sdk.Sweep.data.subscribe({
        onAdded: function (index: any, item: any, collection: any) {
          //console.log('sweep added to the collection', index, item, collection);
        },
        onRemoved: function (index: any, item: any, collection: any) {
          //console.log('sweep removed from the collection', index, item, collection);
        },
        onUpdated: function (index: any, item: any, collection: any) {
          //console.log('sweep updated in place in the collection', index, item, collection);
        },
        onCollectionUpdated: function (collection: any) {
          // console.log("the entire up-to-date collection", collection);
          pointsArray = [];
          for (let key in collection) {
            let value = collection[key];

            if (value.position.x < minX) {
              minX = value.position.x;
            }
            if (value.position.z < minZ) {
              minZ = value.position.z;
            }
            if (value.position.x > maxX) {
              maxX = value.position.x;
            }
            if (value.position.z > maxZ) {
              maxZ = value.position.z;
            }
          }
          for (let key in collection) {
            let value = collection[key];

            const pointMarkerWidth = 30;
            const pointMarkerHeight = 30;

            const leftPercentage = ((value.position.x - minX) / (maxX - minX)) * 100;
            const topPercentage = ((value.position.z - minZ) / (maxZ - minZ)) * 100;

            let offsetX = pointMarkerWidth / 2;
            let offsetY = pointMarkerHeight / 2;

            let LEFT;
            let TOP;
            if ((props.miniMapRotation === null || props.miniMapRotation === 0)) {
              LEFT = leftPercentage - (offsetX * 100 / mapRef.current.width);
              TOP = topPercentage - (offsetY * 100 / mapRef.current.height);
            } else {
              LEFT = leftPercentage - (offsetX * 100 / mapRef.current.height);
              TOP = topPercentage - (offsetY * 100 / mapRef.current.width);
            }

            pointsArray.push({ id: value.sid, left: LEFT, top: TOP });
          }

          // console.log("pointsArray:", pointsArray);
          setMapPoints((prev: any) => {
            return [...pointsArray];
          });
        },
      });
    }
  }, [mapRef.current, mapHeight, mapWidth]);

  useEffect(() => {
    if (currentLocMarkerRef.current && viewConeRef.current && mapRef.current) {
      props.sdk.Sweep.current.subscribe(function (currentSweep: any) {
        if (currentSweep.sid === "") {
          console.log("Not currently stationed at a sweep position");
        } else if (mapRef.current !== null) {
          var currPos = currentSweep.position;
          const leftPercentage = ((currPos.x - minX) / (maxX - minX)) * 100;
          const topPercentage = ((currPos.z - minZ) / (maxZ - minZ)) * 100;

          const offsetX_currLocMarker = 20 / 2;
          const offsetY_currLocMarker = 20 / 2;

          const offsetX_Viewcone = 60 / 2;
          const offsetY_Viewcone = 60 / 2;

          let LEFT_currLocMarker;
          let TOP_currLocMarker;

          let LEFT_Viewcone;
          let TOP_Viewcone;
          if ((props.miniMapRotation === null || props.miniMapRotation === 0)) {
            LEFT_currLocMarker = leftPercentage - (offsetX_currLocMarker * 100 / mapRef.current.width);
            TOP_currLocMarker = topPercentage - (offsetY_currLocMarker * 100 / mapRef.current.height);

            LEFT_Viewcone = leftPercentage - (offsetX_Viewcone * 100 / mapRef.current.width);
            TOP_Viewcone = topPercentage - (offsetY_Viewcone * 100 / mapRef.current.height);
          } else {
            LEFT_currLocMarker = leftPercentage - (offsetX_currLocMarker * 100 / mapRef.current.height);
            TOP_currLocMarker = topPercentage - (offsetY_currLocMarker * 100 / mapRef.current.width);

            LEFT_Viewcone = leftPercentage - (offsetX_Viewcone * 100 / mapRef.current.height);
            TOP_Viewcone = topPercentage - (offsetY_Viewcone * 100 / mapRef.current.width);
          }

          currentLocMarkerRef.current.style.left = `${LEFT_currLocMarker}%`;
          currentLocMarkerRef.current.style.top = `${TOP_currLocMarker}%`;

          viewConeRef.current.style.left = `${LEFT_Viewcone}%`;
          viewConeRef.current.style.top = `${TOP_Viewcone}%`;
        }
      });
    }
  }, [currentLocMarkerRef.current, viewConeRef.current, mapRef.current, mapLoaded]);

  const moveToPoint = (event: any) => {
    console.log("SDK", props.sdk);
    return props.sdk.Sweep.moveTo(event.target.id, {
      transition: props.sdk.Sweep.Transition.FADEOUT,
    })
      .then()
      .catch();
  };

  if (!props.showMiniMap) {
    return null;
  }

  return (
    <div className="allContainer">
      <div className="mapFrameContainer">
        <img src={MapFrame} className="mapFrameImg" />
      </div>

      <div className="mapContainer">
        <img
          onLoad={(image: any) => {
            setMapLoaded(true);
            setMapWidth(image.nativeEvent.srcElement.naturalWidth);
            setMapHeight(image.nativeEvent.srcElement.naturalHeight);
          }}
          ref={mapRef}
          className="map"
          src={`${live360DataBucket}/${props.minimapUrl}`}
          style={{
            width: mapAllContainerSize_X,
          }}
        />
        <div
          className="sweepPointsContainer"
          style={
            (props.miniMapRotation === null || props.miniMapRotation === 0) ?
              {
                width: mapAllContainerSize_X * props.miniMapRatio_X,
                height: mapAllContainerSize_Y * props.miniMapRatio_Y * (mapHeight / mapWidth)
              }
              :
              {
                width: mapAllContainerSize_X * props.miniMapRatio_X * (mapHeight / mapWidth),
                height: mapAllContainerSize_Y * props.miniMapRatio_Y,
                rotate: props.miniMapRotation + "deg"
              }
          }
        >
          <img
            ref={viewConeRef}
            className="viewcone"
            width={60}
            height={60}
            src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/viewRange_v2.png"
          />

          <img
            ref={currentLocMarkerRef}
            className="points"
            width={20}
            height={20}
            src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/star.png"
            style={{ zIndex: 103 }}
          />
          {mapLoaded && mapPoints.length > 0 &&
            mapPoints.map((point) => {
              // console.log("point: ", point);
              return (
                <img
                  src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/areaMarker.png"
                  className="points"
                  height={pointMarkerHeight}
                  width={pointMarkerWidth}
                  id={point.id}
                  key={point.id}
                  style={{
                    left: `${point.left}%`,
                    top: `${point.top}%`,
                    zIndex: 102,
                  }}
                  onClick={moveToPoint}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MiniMap;
