import * as React from "react";
import { useRef, useState } from "react"
import { Auth } from "aws-amplify";
import { useHistory, Link } from 'react-router-dom';
import { subFolderName } from "../Live360Config"

export interface ChangePasswordPageProps { }

export default function ChangePasswordPage(props: ChangePasswordPageProps) {
  const [changePassword_state, setchangePassword_state] = useState("noError")
  const history = useHistory();
  const inputRef_oldPassword = useRef(null);
  const inputRef_newPassword = useRef(null);
  const inputRef_newPassword2 = useRef(null);

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) { }
  };

  const changePswd = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (inputRef_oldPassword.current.value === "" || inputRef_newPassword.current.value === "" || inputRef_newPassword2.current.value === "") {
          setchangePassword_state("emptyField_error")
        }
        else if (inputRef_newPassword.current.value != inputRef_newPassword2.current.value) {
          setchangePassword_state("mismatch_error")
        }
        else if (inputRef_oldPassword.current.value === inputRef_newPassword.current.value) {
          setchangePassword_state("sameAsOld_error")
        } else {
          setchangePassword_state("noError")
          return Auth.changePassword(user, inputRef_oldPassword.current.value, inputRef_newPassword.current.value);
        }
        return ("")
      })
      .then((data) => {
        if (data === "SUCCESS") {
          signOut();
          let path = subFolderName === null ? "/login" : `/${subFolderName}/login`
          history.push(path);
        }
      })
      .catch((err) => {
        setchangePassword_state("notAuthorized_error")
      });
  };

  return (
    <div id="changePasswordPage">
      <div id="CPP_mainSection">
        <p id="CPP_mainHeader">Change password</p>
        <p className="CPP_inputFiledHeader">Enter Old password</p>
        <div className='credentialContainer'>
          <input className='inputField' ref={inputRef_oldPassword} type="password" placeholder="Old password" />
        </div>

        <p className="CPP_inputFiledHeader">Enter New password</p>
        <div className='credentialContainer'>
          <input className='inputField' ref={inputRef_newPassword} type="password" placeholder="New password" />
        </div>

        <p className="CPP_inputFiledHeader">Re-enter New password</p>
        <div className='credentialContainer'>
          <input className='inputField' ref={inputRef_newPassword2} type="password" placeholder="Re-enter new password" />
        </div>

        <button id="CPP_submitButton" onClick={changePswd}>Submit</button>
        <div id="cancelButtonDiv">
          <Link id='cancelButton' to={subFolderName === null ? `/` : `/${subFolderName}/`}>Cancel</Link>
        </div>

        <div id={changePassword_state === "notAuthorized_error" || changePassword_state === "emptyField_error" || changePassword_state === "mismatch_error" || changePassword_state === "sameAsOld_error" ? "errorDiv" : "hiddenElement"}>
          <p id={changePassword_state === "notAuthorized_error" ? "errorText" : "hiddenElement"}>Old password incorrect</p>
          <p id={changePassword_state === "emptyField_error" ? "errorText" : "hiddenElement"}>Fields cannot be empty</p>
          <p id={changePassword_state === "mismatch_error" ? "errorText" : "hiddenElement"}>password mismatch</p>
          <p id={changePassword_state === "sameAsOld_error" ? "errorText" : "hiddenElement"}>new password is same as old password</p>
        </div>
      </div>
    </div>
  );
}
