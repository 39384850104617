import { Auth } from "aws-amplify";
import axios from "axios";
import { API_URL } from "../Live360Config";

export const getCurrentUserDetails = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((sessionData) => {
        console.log("sessionData: ", sessionData);
        const currentUserName = sessionData.getIdToken().payload.given_name;
        const sub: string = sessionData.getIdToken().payload.sub;
        const jwtToken: string = sessionData.getIdToken().getJwtToken();
        const isMasterAccount: boolean =
          sessionData.getIdToken().payload.companyId === "";
        console.log("isMasterAccount: ", isMasterAccount);
        resolve({ sub, jwtToken, currentUserName, isMasterAccount });
      })
      .catch((err) => {
        reject("no-user");
      });
  });
};

export const getCompanyDivisionID = async (jwtToken: string, sub: string) => {
  let URL = `${API_URL}/users/auth`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: jwtToken,
  };

  let data = {
    sub: sub,
  };

  const response = await axios.get(URL, {
    headers: headers,
    data: data,
  });

  console.log("auth request: ", data);

  let CMS_response = response.data;

  var divisionIdList = "";
  for (
    let index = 0;
    index < CMS_response["body"]["authInfo"]["division_list"].length;
    index++
  ) {
    divisionIdList =
      divisionIdList +
      CMS_response["body"]["authInfo"]["division_list"][index]["division_id"] +
      ",";
  }

  const divisionList = divisionIdList.substring(0, divisionIdList.length - 1);

  return [CMS_response["body"]["authInfo"]["company_id"], divisionList];
};

export const getSecurityDetails = async (jwtToken: string) => {
  let URL = `${API_URL}/securityinfo`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: jwtToken,
  };

  const response = await axios.get(URL, {
    headers: headers,
  });

  return response.request.responseText;
};

export const getContent = async (
  jwtToken: string,
  company_division_id: string[],
  limit: number,
  offset: number
) => {
  let URL =
    `${API_URL}/content?` +
    "company_id=" +
    company_division_id[0] +
    "&division_id_list=" +
    company_division_id[1] +
    "&limit=" +
    limit +
    "&offset=" +
    offset;

  const headers = {
    "Content-Type": "application/json",
    Authorization: jwtToken,
  };

  const response = await axios.get(URL, {
    headers: headers,
  });

  return JSON.parse(response.request.responseText);
};

export const getVideoLink = async (
  jwtToken: string,
  video_id: string,
  division_id_list: string[]
) => {
  //send the video_id and the list of division_ids
  //check the video jsons to get the video links

  const URL =
    `${API_URL}/videocontent/content?video_id=` +
    video_id +
    "&division_id_list=" +
    division_id_list; //maybe need to change

  const headers = {
    "Content-Type": "application/json",
    Authorization: jwtToken,
  };

  const response = await axios.get(URL, {
    headers: headers,
  });

  return response.data;
};

export const getCompanies = async (jwtToken: string) => {
  //Just get the list of all companies

  const URL = `${API_URL}/company`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: jwtToken,
  };

  const response = await axios.get(URL, {
    headers: headers,
  });

  console.log("companies: ", response.data);

  return response.data;
};

export const getDivisions = async (jwtToken: string, companyId: string) => {
  //send the chosen company id and get all divisions in that company

  console.log("company_id", companyId);
  const URL = `${API_URL}/division`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: jwtToken,
  };

  let data = {
    company_id: `${companyId}`,
  };

  console.log("division parameters: ", data);

  const response = await axios.get(URL, {
    headers: headers,
    params: data,
  });

  console.log("divisions: ", response.data);

  return response.data;
};
