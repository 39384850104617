import * as React from "react";
import * as ReactDom from "react-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./styleSheet/main.css";
import "./styleSheet/login.css";
import "./styleSheet/footer.css";
import "./styleSheet/contentListPage.css";
import "./styleSheet/spaceBlock.css";
import "./styleSheet/Map.css";
import "./styleSheet/externalFileLst.css";
import "./styleSheet/InfoPopUpPanel.css";
import "./styleSheet/changePasswordPage.css";
import "./styleSheet/PasswordResetPage.css";

import RouteHandler from "./components/RouteHandler";

ReactDom.render(<RouteHandler />, document.getElementById("content"));
