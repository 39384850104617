import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

import { API_URL, subFolderName } from "../Live360Config";
import SpaceBlock from "./SpaceBlock";
import LoginPage from "./LoginPage";
import LiveXR360_bh from "../assets/images/LiveXR360_bh.png";

import "react-awesome-slider/dist/styles.css";
import {
  getCompanyDivisionID,
  getContent,
  getCurrentUserDetails,
  getSecurityDetails,
} from "../api/api";

var currentUserName: string = "";

console.log("Auth object:", Auth);

// const signOut = async () => {
//   try {
//     await Auth.signOut();
//   } catch (error) {
//   }
// }

var limit: number = 50;
var offset: number = 0;
var contentListForView: any = [];
var currentURL: string = "";
let securityDetails = "";

function ContentListPage() {
  const [listState, SetlistState] = useState("loading");
  const [viewStyle, SetViewStyle] = useState("grid");

  currentURL = window.location.href;

  getCurrentUserDetails()
    .then(async (result: any) => {
      currentUserName = result["currentUserName"];
      securityDetails = await getSecurityDetails(result["jwtToken"]);

      const result2 = await getCompanyDivisionID(
        result["jwtToken"],
        result["sub"]
      );
      const result3 = await getContent(
        result["jwtToken"],
        result2,
        limit,
        offset
      );
      contentListForView = result3["body"]["contentList"];
      SetlistState("done");
    })
    .catch((err) => {
      SetlistState("notPermitted");
    });

  const signOut = async () => {
    try {
      await Auth.signOut();
      SetlistState("notPermitted");
    } catch (error) {}
  };

  const authStateUpdate = () => {
    SetlistState("loading");
  };

  const viewStyleGRID = () => {
    SetViewStyle("grid");
  };
  const viewStyleLIST = () => {
    SetViewStyle("list");
  };

  const togglePolicyList = () => {
    var temp = Array.from(
      document.getElementsByClassName("policyContainerButton_links")
    );
    if (temp.length > 0) {
      temp.forEach((value) => {
        value.setAttribute("class", "policyContainerButton_links_hidden");
      });
    } else {
      temp = Array.from(
        document.getElementsByClassName("policyContainerButton_links_hidden")
      );
      temp.forEach((value) => {
        value.setAttribute("class", "policyContainerButton_links");
      });
    }
  };

  const toggleSettingsContainer = () => {
    var temp = document.getElementById("CLP_settingsContainer");
    if (temp != null) {
      temp.setAttribute("id", "CLP_settingsContainer_hidden");
    } else {
      var temp = document.getElementById("CLP_settingsContainer_hidden");
      temp.setAttribute("id", "CLP_settingsContainer");
    }
  };

  if (listState === "notPermitted") {
    return (
      <LoginPage
        from="ContentListPage"
        loginSuccess={() => authStateUpdate()}
      />
    );
  } else if (listState === "loading") {
    return <h2 className="loadingText">Loading...</h2>;
  } else {
    return (
      <div id="contentListPage">
        <div id="topBarContainer">
          {/* <img id="CLP_companyLogo" src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/company_logo.png" alt="" /> */}
          <img
            id="CLP_companyLogo"
            src={LiveXR360_bh}
            alt=""
          />
          <div id="userDetailsSection">
            <img
              id="CLP_userIcon"
              src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/user_icon_01.png"
              alt=""
            />
            <p id="CLP_userNameText">{`${currentUserName}`}</p>
            <div id="CLP_BBU"></div>
            <img
              id="CLP_settingsIcon"
              src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/setting_icon.png"
              alt=""
              onClick={toggleSettingsContainer}
            />
            <div id="CLP_settingsContainer_hidden">
              {/* <a href="https://live360-alpha.live-360.live-xr.com/cms/index.html" target="_blank">Management Portal</a> */}
              {/* <a href={currentURL.includes("test-live360.live-xr.link")?"https://test-live360.live-xr.link/cms/index.html":"https://live360-alpha.live-360.live-xr.com/cms/index.html"} target="_blank">Management Portal</a> */}
              <a href={`${currentURL}cms/index.html`} target="_blank">
                Management Portal
              </a>
              {/* <a className="disabledElement" href="https://live360-alpha.live-360.live-xr.com/cms/index.html" target="_blank">My Profile</a> */}
              {/* <p className="disabledElement">My Profile</p> */}
              {/* <a href="" className="disabledElement">Edit Profile</a> */}
              {/* <p className="disabledElement">Edit Profile</p> */}
              {/* <a href="">Change Password</a> */}
              <Link
                to={
                  subFolderName === null
                    ? `/ChangePassword`
                    : `/${subFolderName}/ChangePassword`
                }
              >
                Change Password
              </Link>
              <p onClick={signOut}>Log-out</p>
            </div>
            {/* <img id="CLP_bellIcon" src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/bell_icon.png" alt="" /> */}
            {/* <img id="CLP_logoutButton" src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/back_button.png" alt="" onClick={signOut} /> */}
            {/* <button type="submit" id="logout-button" onClick={signOut}></button> */}
          </div>
        </div>
        <div id="mainSection">
          <div id="leftSidebarContainer">
            <div id="viewStyleButtonContainer">
              <button
                type="button"
                className={
                  viewStyle == "grid"
                    ? "viewStyleChanger_active"
                    : "viewStyleChanger"
                }
                onClick={viewStyleGRID}
              >
                <img
                  src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/grid_icon.png"
                  alt=""
                />
                <p>GRID</p>
              </button>
              <button
                type="button"
                className={
                  viewStyle == "list"
                    ? "viewStyleChanger_active"
                    : "viewStyleChanger"
                }
                onClick={viewStyleLIST}
              >
                <img
                  src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/list_icon.png"
                  alt=""
                />
                <p>LIST</p>
              </button>
            </div>

            <div id="policyContainer">
              <button
                type="button"
                className="policyContainerButton"
                onClick={togglePolicyList}
              >
                Policy
              </button>
              {JSON.parse(securityDetails).body["infoList"].map((item: any) => (
                <a
                  className="policyContainerButton_links_hidden"
                  href={`${item["url"]}`}
                  key={item["title"]}
                  target="blank"
                >
                  {item["title"]}
                </a>
              ))}
              <a
                href="https://premiumartsinc.freshdesk.com/support/solutions"
                className="policyContainerButton"
              >
                Help
              </a>
              <a
                href="https://premiumartsinc.freshdesk.com"
                target="_blank"
                className="policyContainerButton"
              >
                Support
              </a>
              <img
                id="CLP_live360Logo"
                src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/PremiumArts_Logo.png"
                alt=""
              />
            </div>
          </div>

          {/* <div id="middleMainPanle">
            <div id={viewStyle == "grid" ? "allSpaces_GRID" : "allSpaces_LIST"}>
              {
                contentListForView.map((item: any) =>
                  <div className={`${viewStyle}`} key={item["title"]}><SpaceBlock spaceBlockDetails={item} displayStyle={viewStyle} /></div>
                )
              }
            </div>
          </div> */}
          <div id={viewStyle == "grid" ? "allSpaces_GRID" : "allSpaces_LIST"}>
            {contentListForView.map((item: any) => (
              <div
                className={`${viewStyle}`}
                key={item["title"] + Math.random()}
              >
                <SpaceBlock spaceBlockDetails={item} displayStyle={viewStyle} />
              </div>
            ))}
          </div>
        </div>
        {/* <div id="leftSidebarContainer">
          <div id="viewStyleButtonContainer">
            <button type="button" className="viewStyleChanger" onClick={viewStyleGRID}>
              <img src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/grid_icon.png" alt="" />
              GRID
            </button>
            <button type="button" className="viewStyleChanger" onClick={viewStyleLIST}>
              <img src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/list_icon.png" alt="" />
              LIST
            </button>
          </div>

          <div id="policyContainer">
            <button type="button" className="policyContainerButton" onClick={togglePolicyList}>Policy</button>
            {
              JSON.parse(securityDetails).body["infoList"].map((item: any) =>
                <a className="policyContainerButton_links" href={`${item["url"]}`} key={item["title"]} target="blank">{item["title"]}</a>
              )
            }
            <a href="" className="policyContainerButton">Help</a>
            <a href="" className="policyContainerButton">Support</a>
            <img id="CLP_live360Logo" src="https://s3.ap-northeast-1.amazonaws.com/imgs.cont/Live360/live360_logo_big.png" alt="" />
          </div>
        </div>

        <div id="middleMainPanle">
          <div id={viewStyle == "grid" ? "allSpaces_GRID" : "allSpaces_LIST"}>
            {
              contentListForView.map((item: any) =>
                <div className={`${viewStyle}`} key={item["title"]}><SpaceBlock spaceBlockDetails={item} displayStyle={viewStyle} /></div>
              )
            }
          </div>
        </div> */}
      </div>
    );
  }
}

export default ContentListPage;
