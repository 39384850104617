import { SceneComponent, IPainter2d, Size, ComponentOutput } from '@mp/common';

type Inputs = {
  //IMGsrc: string | null;
  IMGsrc_Decontamination: "x",
  IMGsrc_Production: "x",
  IMGsrc_Standby: "x",
  srcPosition: { x: number, y: number };
  srcSize: Size;
  destPosition: { x: number, y: number };
  destSize: Size;
  IoT_URL_Decontamination: string;
  IoT_URL_Production: string;
  IoT_URL_Standby: string;
  dataRow: number;
  dataFieldName_Decontamination: string;
  dataFieldName_Production: string;
  dataFieldName_Standby: string;
  updateInterval: number;
};

type Outputs = {
  painter: IPainter2d | null;
} & ComponentOutput;

class IotStatusVisualizer extends SceneComponent implements IPainter2d {
  private image: HTMLImageElement | null = null;
  private currIoT_state_Decontamination: string="";
  private currIoT_state_Production: string="";
  private currIoT_state_Standby: string="";
  private currentTime: number = 0;
  private nextUpdate: number = 0;
  private IMGsrc: string="x";

  inputs: Inputs = {
    IMGsrc_Decontamination: "x",
    IMGsrc_Production: "x",
    IMGsrc_Standby: "x",
    srcPosition: { x: 0, y: 0 },
    srcSize: { w: 64, h: 64 },
    destPosition: { x: 0, y: 0 },
    destSize: { w: 64, h: 64 },
    IoT_URL_Decontamination: "x",
    IoT_URL_Production: "x",
    IoT_URL_Standby: "x",
    dataRow: 0,
    dataFieldName_Decontamination: "x",
    dataFieldName_Production: "x",
    dataFieldName_Standby: "x",
    updateInterval: 1000
  }

  outputs = {
    painter: null,
  } as Outputs;

  onInit() {
    this.outputs.painter = this;
    this.maybeLoadImage();
  }

  onInputsUpdated() {
    this.maybeLoadImage();
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    if (!this.image) {
      return;
    }

    context2d.clearRect(0, 0, this.inputs.destSize.w, this.inputs.destSize.h);
    if (this.image.width > 0) {
      context2d.drawImage(this.image,
        this.inputs.srcPosition.x, this.inputs.srcPosition.y,
        this.inputs.srcSize.w, this.inputs.srcSize.h,
        this.inputs.destPosition.x, this.inputs.destPosition.y,
        this.inputs.destSize.w, this.inputs.destSize.h);
    }
  }

  private maybeLoadImage() {
    this.image = null;

    if (this.IMGsrc !== null && this.IMGsrc !== '') {
      const that = this;
      this.image = new Image();
      this.image.crossOrigin = 'anonymous';
      this.image.src = this.IMGsrc;
      this.image.onload = function(event: Event) {
        that.notify('paint.ready');
      };
    }

    this.notify('paint.ready');
  }
  onTick(delta:number){
    this.currentTime += delta;
    if(this.currentTime>this.nextUpdate){
      this.nextUpdate+=this.inputs.updateInterval;

      let request_1 = new XMLHttpRequest();
      request_1.open("GET", this.inputs.IoT_URL_Decontamination);
      request_1.setRequestHeader('Accept', 'application/json');
      request_1.setRequestHeader('Content-Type', 'application/json');
      request_1.setRequestHeader('appKey', '9bc5f4be-7634-439b-97d2-062aa2ef0dad');
      request_1.send();
      request_1.onload = () => {
        if (request_1.status === 200) {
          var parseData = JSON.parse(request_1.response);
          if (parseData && parseData.rows[this.inputs.dataRow] && parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Decontamination] != null) {
              //console.log("Only the data field .rows[0][] : "+(parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Decontamination]));
              if(this.currIoT_state_Decontamination!==parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Decontamination]){
                this.currIoT_state_Decontamination=parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Decontamination];

                //console.log("Value of "+this.inputs.dataFieldName_Decontamination+" : "+this.currIoT_state_Decontamination);

                if(parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Decontamination]){
                  this.IMGsrc=this.inputs.IMGsrc_Decontamination;
                }
              }
          }
        } else {
	        console.log("LOL IT NO WORK GG Decon");
          console.log(`error ${request_1.status} ${request_1.statusText}`);
        }
      };

      let request_2 = new XMLHttpRequest();
      request_2.open("GET", this.inputs.IoT_URL_Production);
      request_2.setRequestHeader('Accept', 'application/json');
      request_2.setRequestHeader('Content-Type', 'application/json');
      request_2.setRequestHeader('appKey', '9bc5f4be-7634-439b-97d2-062aa2ef0dad');
      request_2.send();
      request_2.onload = () => {
        if (request_2.status === 200) {
          var parseData = JSON.parse(request_2.response);
          if (parseData && parseData.rows[this.inputs.dataRow] && parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Production] != null) {
              //console.log("Only the data field .rows[0][] : "+(parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Production]));
              if(this.currIoT_state_Production!==parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Production]){
                this.currIoT_state_Production=parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Production];

                //console.log("Value of "+this.inputs.dataFieldName_Production+" : "+this.currIoT_state_Production);

                if(parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Production]){
                  this.IMGsrc=this.inputs.IMGsrc_Production;
                }
              }
          }
        } else {
	        console.log("LOL IT NO WORK GG Prod");
          console.log(`error ${request_2.status} ${request_2.statusText}`);
        }
      };

      let request_3 = new XMLHttpRequest();
      request_3.open("GET", this.inputs.IoT_URL_Standby);
      request_3.setRequestHeader('Accept', 'application/json');
      request_3.setRequestHeader('Content-Type', 'application/json');
      request_3.setRequestHeader('appKey', '9bc5f4be-7634-439b-97d2-062aa2ef0dad');
      request_3.send();
      request_3.onload = () => {
        if (request_3.status === 200) {
          var parseData = JSON.parse(request_3.response);
          if (parseData && parseData.rows[this.inputs.dataRow] && parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Standby] != null) {
              //console.log("Only the data field .rows[0][] : "+(parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Standby]));
              if(this.currIoT_state_Standby!==parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Standby]){
                this.currIoT_state_Standby=parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Standby];

                //console.log("Value of "+this.inputs.dataFieldName_Standby+" : "+this.currIoT_state_Standby);

                if(parseData.rows[this.inputs.dataRow][this.inputs.dataFieldName_Standby]){
                  this.IMGsrc=this.inputs.IMGsrc_Standby;
                }
              }
          }
        } else {
	        console.log("LOL IT NO WORK GG Stand");
          console.log(`error ${request_3.status} ${request_3.statusText}`);
        }
      };
      this.maybeLoadImage();
    }
  }
}

export const IotStatusVisualizerType = 'mp.IotStatusVisualizer';
export function makeIotStatusVisualizer() {
  return new IotStatusVisualizer();
}
