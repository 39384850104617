import * as React from 'react';
import { useRef } from "react"
import { useHistory, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { subFolderName } from "../Live360Config"

export interface IPasswordResetPageProps {
}

export default function PasswordResetPage(props: IPasswordResetPageProps) {
    const inputRef_userName = useRef(null);
    const inputRef_code = useRef(null);
    const inputRef_newPassword = useRef(null);
    const history = useHistory();

    const requestReset = () => {
        //this sends the reset code to the mail
        Auth.forgotPassword(inputRef_userName.current.value)
            .then(data => {
                console.log(data)
                document.getElementById("RequestCodeButton").setAttribute("id", "hiddenElement")
                document.getElementById("submitResetSection_hidden").setAttribute("id", "submitResetSection")
            })
            .catch(err => console.log(err)); //commented temporarily.must enable 
    }

    const submitReset = () => {
        //this submits the new password to cognito and updates the password
        Auth.forgotPasswordSubmit(inputRef_userName.current.value, inputRef_code.current.value, inputRef_newPassword.current.value)
            .then(data => {
                console.log(data)
                if (data === "SUCCESS") {
                    let path = subFolderName === null ? "/login" : `/${subFolderName}/login`
                    history.push(path);
                }
            })
            .catch(err => console.log(err));
    }

    return (
        <div id='PasswordResetPage'>
            <div id='PRP_mainSection'>
                <p id="PRP_mainHeader">Reset password</p>
                <div id='requestResetSection'>
                    <p className="PRP_inputFiledHeader">Email</p>
                    <div className='credentialContainer'>
                        <input className='inputField' ref={inputRef_userName} type="text" placeholder="enter email" />
                    </div>
                    <button id='RequestCodeButton' onClick={requestReset}>Request password reset code</button>
                </div>

                <div id='submitResetSection_hidden'>
                    <p id='checkEmailText' className="PRP_inputFiledHeader">Check email for reset code</p>
                    <div className='credentialContainer'>
                        <input className='inputField' ref={inputRef_code} type="text" placeholder='enter your reset code' />
                    </div>

                    <p className="PRP_inputFiledHeader">Enter new password</p>
                    <div className='credentialContainer'>
                        <input className='inputField' ref={inputRef_newPassword} type="password" placeholder='enter your new password' />
                    </div>

                    <p className="PRP_inputFiledHeader">Re-enter new password</p>
                    <div className='credentialContainer'>
                        <input className='inputField' type="password" placeholder='Re-enter your new password' />
                    </div>

                    <button id='SubmitResetButton' onClick={submitReset} >Reset password</button>
                </div>
                <div id='cancelButtonDiv'>
                    <Link id='cancelButton' to={subFolderName === null ? `/` : `/${subFolderName}/`}>Cancel</Link>
                </div>
            </div>
        </div>
    );
}
